// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TaskViews from "../../blocks/TaskViews/src/TaskViews";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Documentation from "../../blocks/Documentation/src/Documentation";
import CentralisedBilling from "../../blocks/CentralisedBilling/src/CentralisedBilling";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Notes from "../../blocks/Notes/src/Notes";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import UserGroups2 from "../../blocks/UserGroups2/src/UserGroups2";
import InventoryManagement2 from "../../blocks/InventoryManagement2/src/InventoryManagement2";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import Location from "../../blocks/location/src/Location";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import ManualTransactions2 from "../../blocks/ManualTransactions2/src/ManualTransactions2";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TaskViews:{
 component:TaskViews,
path:"/TaskViews"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Documentation:{
 component:Documentation,
path:"/Documentation"},
CentralisedBilling:{
 component:CentralisedBilling,
path:"/CentralisedBilling"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Notes:{
 component:Notes,
path:"/Notes"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
MultilevelApproval:{
 component:MultilevelApproval,
path:"/MultilevelApproval"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
UserGroups2:{
 component:UserGroups2,
path:"/UserGroups2"},
InventoryManagement2:{
 component:InventoryManagement2,
path:"/InventoryManagement2"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Location:{
 component:Location,
path:"/Location"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
ManualTransactions2:{
 component:ManualTransactions2,
path:"/ManualTransactions2"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;